import React, { useState } from "react"

import Layout from "../components/Layout"
import TimetableNotice from "../components/Content/TimetableNotice"
import Heading from "../components/Layout/heading"
import ContactForm from "../components/Forms/contact"
import GoogleMaps from "../components/GoogleMaps"

import SEO from "../components/SEO"

const Contact = (props) => {
  const [showInfoWindow, setShowInfoWindow] = useState(true)

  return (
    <Layout location={props.location}>
      <SEO
        title="Get in Touch"
        description="If you would like to know more about our fantastic range of tours or
            anything that we can help you with please contact one of our
            friendly staff who will be more than happy to help."
      />
      <TimetableNotice />
      <Heading title="Get in Touch" />
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          <p>
            If you would like to know more about our fantastic range of tours or
            anything that we can help you with please contact one of our
            friendly staff who will be more than happy to help.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <GoogleMaps
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div style={{ height: `500px`, marginBottom: `2rem` }} />
            }
            mapElement={<div style={{ height: `100%` }} />}
            mapCenter={{ lat: -34.887686, lng: 150.641994 }}
            isMarkerShown
            markerCenter={{ lat: -34.8885, lng: 150.64115 }}
            zoom={13}
            toggleInfoWindow={() => {
              setShowInfoWindow(!showInfoWindow)
            }}
            showInfoWindow={showInfoWindow}
            infoWindowData={
              <>
                <h5 className="googlemaps-heading">Stuarts Coaches</h5>
                <p className="googlemaps-text">
                  339 Greenwell Point Road
                  <br />
                  Worrigee, NSW, 2541
                </p>
              </>
            }
          />
        </div>
        <div className="col-12">
          <ContactForm />
        </div>
      </div>
    </Layout>
  )
}

export default Contact
